import React, { Component } from 'react';
import insured from "../../assets/images/ipid/insured.png";
import notInsured from "../../assets/images/ipid/not_insured.png";
import restrictions from "../../assets/images/ipid/restrictions.png";
import worldwide from "../../assets/images/ipid/worldwide.png";
import obligations from "../../assets/images/ipid/obligations.png";
import premium from "../../assets/images/ipid/premium.png";
import coveragePeroid from "../../assets/images/ipid/coverage_peroid.png";
import withdrawn from "../../assets/images/ipid/withdrawn.png";
import tick from "../../assets/images/tick.png";
import cross from "../../assets/images/cross.png";
import exclamation from "../../assets/images/exclamation_mark.png";
import { Trans } from "gatsby-plugin-react-i18next";
import { env } from '../../app.constant';
class InsuranceProductInformationDocumentComponent extends Component {

  /* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
  render() {
    return (
      <div className='insurance_MD'>
        <p className="display-18"><strong><Trans>docs.insurance.title</Trans></strong></p>
        <div style={{ fontSize: '15px' }}>

        {env.GATSBY_COUNTRY === "FI" ? (
          <div>
            <div className="bold">
              <span style={{ fontWeight: '500' }}><Trans>docs.insurance.label3</Trans> </span>
              <span><Trans>docs.insurance.label4</Trans></span>
            </div>
            <div className="bold">
              <span style={{ fontWeight: '500' }}><Trans>docs.insurance.label1</Trans> </span>
              <span><Trans>docs.insurance.label2</Trans></span>
            </div>
          </div>
        ):(
          <div>
            <div className="bold">
              <span style={{ fontWeight: '500' }}><Trans>docs.insurance.label1</Trans> </span>
              <span><Trans>docs.insurance.label2</Trans></span>
            </div>
            <div className="bold">
              <span style={{ fontWeight: '500' }}><Trans>docs.insurance.label3</Trans> </span>
              <span><Trans>docs.insurance.label4</Trans></span>
            </div>
          </div>
        )}
        </div>
        <br />
        <div className="display-16">
          <p>
            <Trans components={{
              1: <a href="https://amtrustinternational.com/about-us/underwriting/" style={{ color: '#0077CB' }} tabIndex="-1" />,
              2: <a href="mailto:enquiries@centralbank.ie" style={{ color: '#0077CB' }} tabIndex="-1" />,
              3: <a href="https://www.centralbank.ie" style={{ color: '#0077CB' }} tabIndex="-1" />
            }}>docs.insurance.label5</Trans>
           </p>
          <p><Trans>docs.insurance.label6</Trans></p>
        </div>
        <div>
          <p className="display-18" style={{ fontWeight: '600' }}><Trans>docs.insurance.label7</Trans></p>
          <label className="display-16"><Trans>docs.insurance.label8</Trans></label><br />
          <p className="display-16"><Trans>docs.insurance.label9</Trans></p>
        </div>

        <div className='grid-responsive2'>
          <div className='ipid grid-2'>
            <div className='gridTempResponsive'>
              <div><p className='p-title'><img src={insured} alt="insured" className='imgTitle' /></p></div>
              <div style={{ padding: '8px', fontSize: '18px', fontWeight: "500" }}><Trans>docs.insurance.label10</Trans></div>
            </div>
            <div className='gridTemp'>
              <div><p  style={{ textAlign: 'center' }}><img src={tick} alt="tick" width='14px' /></p></div>
              <div style={{ fontSize: '15px', paddingBottom: '15px' }}><Trans>docs.insurance.label11</Trans></div>
              <div></div>
              <div><p style={{ fontSize: '16px', fontWeight: '500' }}><Trans>docs.insurance.label12</Trans></p></div>
              <div></div>
              <div>
                <div className='gridTemp'>
                  <div><p style={{ textAlign: 'center' }}><img src={tick} alt="tick" width='14px' /></p></div>
                  <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label13</Trans></div>
                  <div><p style={{ textAlign: 'center' }}><img src={tick} alt="tick" width='14px' /></p></div>
                  <div style={{ fontSize: '15px', paddingBottom: '15px' }}><Trans>docs.insurance.label14</Trans></div>
                </div>
              </div>
              <div></div>
              <div><p style={{ fontSize: '16px', fontWeight: '500' }}><Trans>docs.insurance.label15</Trans></p></div>
              <div></div>
              <div>
                <div className='gridTemp'>
                  <div><p style={{ textAlign: 'center' }}><img src={tick} alt="tick" width='14px' /></p></div>
                  <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label16</Trans></div>
                  <div><p style={{ textAlign: 'center' }}><img src={tick} alt="tick" width='14px' /></p></div>
                  <div style={{ fontSize: '15px', paddingBottom: '15px' }}><Trans>docs.insurance.label17</Trans></div>
                  <div><p style={{ textAlign: 'center' }}><img src={tick} alt="tick" width='14px' /></p></div>
                  <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label18</Trans></div>
                  <div><p style={{ textAlign: 'center' }}><img src={tick} alt="tick" width='14px' /></p></div>
                  <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label19</Trans></div>
                </div>
              </div>
            </div>
          </div>

          <div className='ipid'>
            <div className='gridTempResponsive'>
              <div><p className='p-title'><img src={notInsured} alt="notInsured" className='imgTitle' /></p></div>
              <div style={{ padding: '8px', fontSize: '18px', fontWeight: "500" }}><Trans>docs.insurance.label20</Trans></div>
            </div>
            <div className='gridTemp'>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label21</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label22</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label23</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label24</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label25</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label26</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label27</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label28</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={cross} alt="cross" width='12px' /></p></div>
              <div style={{ fontSize: '15px' }}><Trans>docs.insurance.label29</Trans></div>
            </div>
          </div>
        </div>

        <div className='grid-responsive3'>
          <div className='ipid ipid-grid-3' style={{marginRight:'4px'}}>
            <div className='gridTemp'>
              <div><p style={{ textAlign: 'center' }}><img src={restrictions} alt="restrictions" className='imgTitle' /></p></div>
              <div style={{ padding: '8px', fontSize: '18px', fontWeight: "500" }}><Trans>docs.insurance.label30</Trans></div>
              <div><p className='p-title' style={{ textAlign: 'center' }}><img src={exclamation} alt="exclamation" height="12px" /></p></div>
              <div style={{padding: '8px', fontSize: '15px' }}><Trans>docs.insurance.label31</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={exclamation} alt="exclamation" height="12px" /></p></div>
              <div style={{padding: '8px', fontSize: '15px' }}><Trans>docs.insurance.label32</Trans></div>
              <div><p style={{ textAlign: 'center' }}><img src={exclamation} alt="exclamation" height="12px" /></p></div>
              <div style={{padding: '8px', fontSize: '15px' }}><Trans>docs.insurance.label33</Trans></div>
            </div>
          </div>

          <div className='ipid ipid-grid-3' style={{marginLeft:'4px', marginRight:'4px'}}>
            <div className='gridTemp'>
              <div><p style={{ textAlign: 'center' }}><img src={worldwide} alt="worldwide" className='imgTitle' /></p></div>
              <div style={{ padding: '8px', fontSize: '18px', fontWeight: "500" }}><Trans>docs.insurance.label34</Trans></div>
            </div>
            <div className='gridTemp'>
                <div><p style={{ textAlign: 'center' }}><img src={tick} alt="tick" width='14px' /></p></div>
                <div style={{ fontSize: '15px', padding: '8px' }}><Trans>docs.insurance.label35</Trans></div>
            </div>
          </div>

          <div className='ipid ipid-grid-3' style={{marginLeft:'4px'}}>
            <div className='gridTemp'>
              <div><p style={{ textAlign: 'center' }}><img src={obligations} alt="obligations" className='imgTitle' /></p></div>
              <div style={{ padding: '8px', fontSize: '18px', fontWeight: "500" }}><Trans>docs.insurance.label36</Trans></div>
            </div>
            {env.GATSBY_COUNTRY === "FI" ? (
              <div className="display-16">
                <label><Trans>docs.insurance.label37</Trans></label>
                <br />
                <ul style={{ paddingLeft: "20px" }}>
                  <li><Trans>docs.insurance.label38</Trans></li>
                  <li><Trans>docs.insurance.label38-a-fi</Trans></li>
                </ul>
                <label><Trans>docs.insurance.label38-b-fi</Trans></label>
                <br />
                <ul style={{ paddingLeft: "20px" }}>
                  <li><Trans>docs.insurance.label39</Trans></li>
                  <li><Trans>docs.insurance.label40</Trans></li>
                </ul>
              </div>
            ) : (
              <div className="display-16">
                <label><Trans>docs.insurance.label37</Trans></label>
                <br />
                <ul style={{ paddingLeft: "20px" }}>
                  <li><Trans>docs.insurance.label38</Trans></li>
                  <li><Trans>docs.insurance.label39</Trans></li>
                </ul>
                <br />
                <label><Trans>docs.insurance.label40</Trans></label>
                <br />
                <ul style={{ paddingLeft: "20px" }}>
                  <li><Trans>docs.insurance.label40-1</Trans></li>
                  <li><Trans>docs.insurance.label40-2</Trans></li>
                  <li><Trans>docs.insurance.label40-3</Trans></li>
                </ul>
              </div>
            )}
          </div>

          <div className='ipid ipid-grid-3' style={{marginRight:'4px'}}>
            <div className='gridTemp'>
              <div><p style={{ textAlign: 'center' }}><img src={premium} alt="premium" className='imgTitle' /></p></div>
              <div style={{ padding: '8px', fontSize: '18px', fontWeight: "500" }}><Trans>docs.insurance.label41</Trans></div>
            </div>
            <div className='display-16'>
              <p><Trans>docs.insurance.label42</Trans></p>
            </div>
          </div>

          <div className='ipid ipid-grid-3' style={{marginLeft:'4px', marginRight:'4px'}}>
            <div className='gridTemp'>
              <div><p style={{ textAlign: 'center' }}><img src={coveragePeroid} alt="coveragePeroid" className='imgTitle' /></p></div>
              <div style={{ padding: '8px', fontSize: '18px', fontWeight: "500" }}><Trans>docs.insurance.label43</Trans></div>
            </div>
            <div className='display-16'>
              <p><Trans>docs.insurance.label44</Trans></p>
            </div>
          </div>

          <div className='ipid ipid-grid-3' style={{marginLeft:'4px'}}>
            <div className='gridTemp'>
              <div><p style={{ textAlign: 'center' }}><img src={withdrawn} alt="withdrawn" className='imgTitle' /></p></div>
              <div style={{ padding: '8px', fontSize: '18px', fontWeight: "500" }}><Trans>docs.insurance.label45</Trans></div>
            </div>
            <div className='display-16'>
              <p><Trans>docs.insurance.label46</Trans></p>
              {env.GATSBY_COUNTRY === "FI" && <p style={{marginBottom:"10px"}}><Trans>docs.insurance.label50</Trans></p>}
              <p style={{marginBottom:"10px"}}><Trans>docs.insurance.label47</Trans></p>
              <p style={{marginBottom:"10px"}}><Trans>docs.insurance.label48</Trans></p>
              <p style={{marginBottom:"10px"}}><Trans>docs.insurance.label49</Trans></p>
              {env.GATSBY_COUNTRY !== "FI" && <p style={{marginBottom:"10px"}}><Trans>docs.insurance.label50</Trans></p>}
            </div>
          </div>
        </div>


      </div>
    )
  }
}

export default InsuranceProductInformationDocumentComponent;
